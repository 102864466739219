// react
import React, { Component } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import SelectSearch from 'react-select-search';
//import SelectSearch from 'react-select-search/dist/cjs/index.js';
import Fuse from 'fuse.js';

//import PasswordStrengthBar from 'react-password-strength-bar';
import ReactPasswordStrength from 'react-password-strength';

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import FacebookLogin from 'react-facebook-login';

import { GoogleLogin } from 'react-google-login';
import Autocomplete from "react-google-autocomplete";

import OtpInput from 'react-otp-input';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';
import { cartRefresh } from '../../store/cart';
import { wishlistRefesh } from '../../store/wishlist';

// data stubs
import theme from '../../data/theme';

import { Config } from '../../config';
import shopApi from '../../api/shop';

const breadcrumb = [
    { title: 'Home', url: '/' },
    { title: 'Login', url: '' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AccountPageLogin extends Component {

    /**
     * [constructor function called when main class init]
     * @param  {Object} props [Object to set global values]
     * @return {Object}
     */
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isLoggedIn: false,
            termsAndConditionsAgreed: false,
            email: '',
            password: '',
            isUserValid: false,
            formFields: {
                email: '',
                password: '',
            },
            registerFormFields: {
                first_name: '',
                last_name: '',
                mobile_number: '',
                email_address: '',
                address: '',
                city: '',
                password: '',
                confirm_password: '',
            },
            isTogglePassword: false,
            type: 'password',
            register_type: 'password',
            register_type_c: 'password',
            isRememberMe: false,
            validationFormErrors: {
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyMobileNumber: false,
                isEmptyPassword: false,
                isNotValidLengthPassword: false,
                isEmptyOTP: false,
            },
            validationRegisterFormErrors: {
                isEmptyFirstName: false,
                isEmptyLastName: false,
                isEmptyMobileNumber: false,
                isEmptyEmail: false,
                isNotValidEmail: false,
                isEmptyCity: false,
                isEmptyAddress: false,
                isEmptyPassword: false,
                isEmptyCPassword: false,
                isNotSameBothPassword: false,
                isNotValidPassword: false,
                isNotValidCPassword: false,
                isEmptyOTP: false
            },
            pageSubHeading: 'Login',
            isEmptyRegisterExistingMobile: false,
            registerMobileNumberUpdate: '',
            isMobileNumberChangeRequested: false,
            pageRegisterSubHeading: 'RegisterNew',
            flag: '',
            openVerificationDialog: false,
            active: 'login_by_email',
            countries: [],
            countryCode: 0,
            countryCodeRegister: 0,
            countryCodeRemember: '27-202',
            countryCodeRegisterRemember: '27-202',
            otp: '',
            registerOTP: '',
            registerOTPToken: '',
            verificationAuth: '',
            verificationEmailAuth: '',
            passwordScore: 0,
            cPasswordScore: 0,
            timerDuration: 60,
            startTimerLogin: false,
            startTimerRegister: false,
            showResendOTPButton: false,
            showResendOTPButtonLogin: false,
            password1Length: false,
            contains1Numbers: false,
            isUpper1Case: false,
            contains1Symbols: false,
            password2Length: false,
            contains2Numbers: false,
            isUpper2Case: false,
            contains2Symbols: false,

            isEmailChangeRequested: false,
            isEmptyRegisterExistingEmail: false,
            isEmptyRegisterExistingisValidEmailAddress: false,
            registerEmailUpdate: '',

        }
    }

    // check to see if there is any number
    checkForNumbers(string, input){
        var matches = string.match(/\d+/g);
        this.setState({
            [input]: matches != null ? true : false
        });
    }

    // check for upper case
    checkForUpperCase(string, input){
        var matches = string.match(/[A-Z]/);
        this.setState({
            [input]: matches != null ? true : false
        });
    }

    // check for symbols
    checkForSymbols(string, input){
        var symbols = new RegExp(/[^A-Z a-z0-9]/);
        this.setState({
            [input]: symbols.test(string) ? true : false
        });
    }

    // handle password
    handleChangeSetRegisterPassword = e =>{
        this.setState({ passwordScore: 0 });
        let targetValue = e.target.value.replace(/\s/g, '');
        //console.log("targetValue", targetValue);
        this.checkForNumbers(targetValue, 'contains1Numbers');
        this.checkForUpperCase(targetValue, 'isUpper1Case');
        this.checkForSymbols(targetValue, 'contains1Symbols');
        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, ['password']: targetValue }
        }));
        this.setState({
            password1Length: targetValue.length < 8 ? false : true
        });
    }

    // handle password
    handleChangeSetRegisterCPassword = e =>{
        this.setState({ passwordScore: 0 });
        let targetValue = e.target.value.replace(/\s/g, '');
        this.checkForNumbers(targetValue, 'contains2Numbers');
        this.checkForUpperCase(targetValue, 'isUpper2Case');
        this.checkForSymbols(targetValue, 'contains2Symbols');
        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, ['confirm_password']: targetValue }
        }));
        this.setState({
            password2Length: targetValue.length < 8 ? false : true
        });
    }


    /**
     *
     */
    async componentDidMount() {

        var isAlreadyLoggedin = (localStorage.getItem(Config.x_token) !== null ? true : false);
        if (isAlreadyLoggedin) {
            this.props.history.push("/account/dashboard");
        }

        var isRememberMeChecked = (localStorage.getItem('isRememberMe') !== null && localStorage.getItem('isRememberMe') === 'true' ? true : false);
        if (isRememberMeChecked) {
            const email = Config.getData(localStorage.getItem('email'));
            const password = Config.getData(localStorage.getItem('password'));
            const countryCodeRemember = (localStorage.getItem('countryCodeRemember') !== null ? localStorage.getItem('countryCodeRemember') : '27-202');
            if(parseInt(email)){
                this.setState({ active : 'login_by_mobile', otp: '' });
            } else {
                this.setState({ active : 'login_by_email', otp: '' });
            }
            this.setState({
                formFields: {
                    email: email,
                    password: password
                },
                countryCode: countryCodeRemember.split("-")[0],
                countryCodeRemember: countryCodeRemember,
                isRememberMe: true
            });
        } else {
            this.setState({
                formFields: {
                    email: '',
                    password: ''
                },
                countryCode: 0,
                countryCodeRemember: '27-202',
                isRememberMe: false
            });
        }

        this.getAllCountries();

        setTimeout(() => {
            this.props.wishlistRefesh();
        }, 1000);
        setTimeout(() => {
            this.props.cartRefresh();
        }, 1500);
    }

    /**
     *
     * @param {*} string
     * @returns
     */
    isValidURL = string => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    /**
     *
     * @param {*} options
     * @returns
     */
    fuzzySearch(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    /**
     *
     * @param {*} options
     * @returns
     */
    fuzzySearchRegister(options) {
        const fuse = new Fuse(options, {
            keys: ['name', 'groupName', 'items.name'],
            threshold: 0.3,
        });

        return (value) => {
            if (!value.length) {
                return options;
            }
            return fuse.search(value);
        };
    }

    handleSetOTP = (otp) => this.setState({ otp });

    handleRegisterSetOTP = (registerOTP) => this.setState({ registerOTP });

    setCountryCode(event){
        this.setState({ countryCode: event.split("-")[0], countryCodeRemember: event });
    }

    setCountryCodeRegister(event){
        this.setState({ countryCodeRegister: event.split("-")[0], countryCodeRegisterRemember: event });
    }

    changeLoginType(type){
        this.setState({ active : type, otp: '' });
    }

    /**
     *
     * @param {*} emailAddress
     * @returns
     */
    isValidEmailAddress(emailAddress){
        const emailAddressRegExp = new RegExp("^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9]" +
          "(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
          "[a-zA-Z0-9])?)*$");
        // A valid e-mail address list is a set of comma-separated tokens, where each token is itself
        // a valid e - mail address.To obtain the list of tokens from a valid e - mail address list,
        // an implementation must split the string on commas.
        return emailAddressRegExp.test(emailAddress);
    };

    /**
     *
     * @param {*} password
     * @returns
     */
    isValidPassword(password){
        const passwrordRegExp = new RegExp("(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$");
        return passwrordRegExp.test(password);
    };

    /**
     * [validateRegisterForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateRegisterForm() {
        var isReturn = true;
        let validationRegisterFormErrors = {
            isEmptyFirstName: false,
            isEmptyLastName: false,
            isEmptyMobileNumber: false,
            isEmptyEmail: false,
            isNotValidEmail: false,
            isEmptyCity: false,
            isEmptyAddress: false,
            isEmptyPassword: false,
            isEmptyCPassword: false,
            isNotSameBothPassword: false,
            isNotValidLenghtPassword: false,
            isNotValidLenghtCPassword: false,
        };

        var first_name = this.state.registerFormFields.first_name;
        var last_name = this.state.registerFormFields.last_name;
        var mobile_number = this.state.registerFormFields.mobile_number;
        var email_address = this.state.registerFormFields.email_address;
        var city = this.state.registerFormFields.city;
        var address = this.state.registerFormFields.address;
        var password = this.state.registerFormFields.password;
        var confirm_password = this.state.registerFormFields.confirm_password;
        var passwordScore = this.state.passwordScore;
        var cPasswordScore = this.state.cPasswordScore;

        if (first_name === '' || first_name === null) {
            validationRegisterFormErrors.isEmptyFirstName = true;
            isReturn = false;
        }
        if (last_name === '' || last_name === null) {
            validationRegisterFormErrors.isEmptyLastName = true;
            isReturn = false;
        }
        if (mobile_number === '' || mobile_number === null) {
            validationRegisterFormErrors.isEmptyMobileNumber = true;
            isReturn = false;
        }
        if (email_address === '' || email_address === null) {
            validationRegisterFormErrors.isEmptyEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            validationRegisterFormErrors.isNotValidEmail = true;
            isReturn = false;
        }
        if (city === '' || city === null) {
            validationRegisterFormErrors.isEmptyCity = true;
            isReturn = false;
        }
        if (address === '' || address === null) {
            validationRegisterFormErrors.isEmptyAddress = true;
            isReturn = false;
        }
        if (password === '' || password === null) {
            validationRegisterFormErrors.isEmptyPassword = true;
            isReturn = false;
        }
        if (confirm_password === '' || confirm_password === null) {
            validationRegisterFormErrors.isEmptyCPassword = true;
            isReturn = false;
        }
        if (password !== '' && confirm_password !== '' && password !== confirm_password) {
            validationRegisterFormErrors.isNotSameBothPassword = true;
            isReturn = false;
        }
        if(password !== ''){
            if(!this.state.contains1Numbers || !this.state.isUpper1Case || !this.state.contains1Symbols || !this.state.password1Length){
                validationRegisterFormErrors.isNotValidPassword = true;
                isReturn = false;
            }
        }
        if (password !== '' && password.length < 8) {
            validationRegisterFormErrors.isNotValidPassword = true;
            isReturn = false;
        }
        if(confirm_password !== ''){
            if(!this.state.contains2Numbers || !this.state.isUpper2Case || !this.state.contains2Symbols || !this.state.password2Length){
                validationRegisterFormErrors.isNotValidCPassword = true;
                isReturn = false;
            }
        }
        if (confirm_password !== '' && confirm_password.length < 8) {
            validationRegisterFormErrors.isNotValidCPassword = true;
            isReturn = false;
        }
        this.setState({ validationRegisterFormErrors });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    signupUser() {
        let isValidated = this.validateRegisterForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.registerAuth);

        let postCredentials = {...this.state.registerFormFields};
        postCredentials.country = this.state.countryCodeRegisterRemember.split("-")[1];
        var countryCode = this.state.countryCodeRegister;
        if(!this.state.countryCodeRegister || this.state.countryCodeRegister == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postCredentials.mobile_number = countryCode + "" + this.state.registerFormFields.mobile_number;
        //console.log("postCredentials", postCredentials);

        fetch(Config.URL+"/new-sign-up", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            //console.log("signupUser response: ", response);
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);

                this.setState({ pageRegisterSubHeading: 'RegisterNew', registerOTP: '', registerOTPToken: '' });
                localStorage.setItem(Config.x_token, Config.setData(response.data.login_access_token));

                let currentPic = '';
                if(response.data.profile_image !== ''){
                    if(this.isValidURL(response.data.profile_image)){
                        currentPic = response.data.profile_image;
                    } else {
                        currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                    }
                }

                let userJSON = {
                    id: response.data.id,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    profile_percentage: response.data.profile_percentage,
                    profile_image: currentPic,
                }
                localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                this.props.history.push("/");

            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateNewRegisterForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateNewRegisterForm() {
        var isReturn = true;
        let validationRegisterFormErrors = {
            isEmptyMobileNumber: false,
            isEmptyEmail: false,
            isNotValidEmail: false
        };

        var mobile_number = this.state.registerFormFields.mobile_number;
        var email_address = this.state.registerFormFields.email_address;

        if(this.state.countryCodeRegisterRemember.split("-")[1] === '202'){
            if (mobile_number === '' || mobile_number === null) {
                validationRegisterFormErrors.isEmptyMobileNumber = true;
                isReturn = false;
            }
        }

        if(this.state.countryCodeRegisterRemember.split("-")[1] !== '202'){
            if (email_address === '' || email_address === null) {
                validationRegisterFormErrors.isEmptyEmail = true;
                isReturn = false;
            }
            if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
                validationRegisterFormErrors.isNotValidEmail = true;
                isReturn = false;
            }
        }
        this.setState({ validationRegisterFormErrors });
        return isReturn;
    }

    /**
     * [verifyNewUser for register]
     * @returns null
     */
    verifyNewUser() {
        let isValidated = this.validateNewRegisterForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.registerAuth);

        let postCredentials = {...this.state.registerFormFields};
        postCredentials.country = this.state.countryCodeRegisterRemember.split("-")[1];
        var countryCode = this.state.countryCodeRegister;
        if(!this.state.countryCodeRegister || this.state.countryCodeRegister == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postCredentials.mobile_number = countryCode + "" + this.state.registerFormFields.mobile_number;
        //console.log("postCredentials", postCredentials);

        //postCredentials.mobile_number = "919081402323";

        fetch(Config.URL+"/verify-new-user", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            //console.log("verifyNewUser response: ", response);
            this.setState({ isLoading: false });
            if(response.status){

                // shopApi.g4SingleEventPush('register_user',{
                //     user_id: '',
                //     first_name: first_name,
                //     last_name: last_name,
                //     mobile_number: mobile_number,
                //     email_address: email_address,
                //     address: address,
                //     city: city,
                // });

                this.setState({ startTimerRegister: true });
                if(this.state.countryCodeRegisterRemember.split("-")[1] === '202'){
                    this.setState({ registerOTPToken: response.OTP_token, pageRegisterSubHeading: 'Verify OTP' });
                } else {
                    this.setState({ registerOTPToken: response.OTP_token, pageRegisterSubHeading: 'Verify Email' });
                }
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [resendEmailVerification for register]
     * @returns null
     */
    resendEmailVerification() {
        let isValidated = this.validateNewRegisterForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.registerAuth);

        let postCredentials = {...this.state.registerFormFields};
        postCredentials.country = this.state.countryCodeRegisterRemember.split("-")[1];
        var countryCode = this.state.countryCodeRegister;
        if(!this.state.countryCodeRegister || this.state.countryCodeRegister == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postCredentials.mobile_number = countryCode + "" + this.state.registerFormFields.mobile_number;
        //console.log("postCredentials", postCredentials);

        fetch(Config.URL+"/verify-new-user", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            //console.log("verifyNewUser response: ", response);
            this.setState({ isLoading: false });
            if(response.status){
                this.setState({ startTimerRegister: false, showResendOTPButton: false });
                this.setState({ registerOTPToken: response.OTP_token, pageRegisterSubHeading: 'Verify Email' });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [resendOTPVerification for register]
     * @returns null
     */
    resendOTPVerification() {
        let isValidated = this.validateNewRegisterForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', Config.registerAuth);

        let postCredentials = {...this.state.registerFormFields};
        postCredentials.country = this.state.countryCodeRegisterRemember.split("-")[1];
        var countryCode = this.state.countryCodeRegister;
        if(!this.state.countryCodeRegister || this.state.countryCodeRegister == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postCredentials.mobile_number = countryCode + "" + this.state.registerFormFields.mobile_number;

        //postCredentials.mobile_number = "919081402323";
        //console.log("postCredentials", postCredentials);

        fetch(Config.URL+"/verify-new-user", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            //console.log("verifyNewUser response: ", response);
            this.setState({ isLoading: false });
            if(response.status){
                this.setState({ startTimerRegister: false, showResendOTPButton: false });
                this.setState({ registerOTPToken: response.OTP_token, pageRegisterSubHeading: 'Verify OTP' });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateForm() {
        var isReturn = true;
        let validationFormErrors = {
            isEmptyEmail: false,
            isNotValidEmail: false,
            isEmptyMobileNumber: false,
            isEmptyPassword: false,
            isNotValidLengthPassword: false,
        };

        const email = this.state.formFields.email;
        const password = this.state.formFields.password;

        if(this.state.active == 'login_by_email'){
            if (email === '' || email === null) {
                validationFormErrors.isEmptyEmail = true;
                isReturn = false;
            }
            if (email !== '' && !parseInt(email) && !this.isValidEmailAddress(email)) {
                validationFormErrors.isNotValidEmail = true;
                isReturn = false;
            }
        } else {
            if (email === '' || email === null) {
                validationFormErrors.isEmptyMobileNumber = true;
                isReturn = false;
            }
        }

        if(this.state.isUserValid){
            if (password === '' || password === null) {
                validationFormErrors.isEmptyPassword = true;
                isReturn = false;
            }
        }
        // if (password !== '' && password.length < 8) {
        //     validationFormErrors.isNotValidLengthPassword = true;
        //     isReturn = false;
        // }
        this.setState({ validationFormErrors });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    authUser() {
        let isValidated = this.validateForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });

        const email = this.state.formFields.email;
        const password = this.state.formFields.password;

        var isRememberMeChecked = (localStorage.getItem('isRememberMe') !== null && localStorage.getItem('isRememberMe') === 'true' ? true : false);
        if (isRememberMeChecked) {
            localStorage.setItem('email', Config.setData(email));
            localStorage.setItem('password', Config.setData(password));
            localStorage.setItem('countryCodeRemember', this.state.countryCodeRemember);
        } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('countryCodeRemember');
            localStorage.removeItem('isRememberMe');
        }

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);

        let postCredentials = {...this.state.formFields};
        if(this.state.active === 'login_by_mobile'){
            if(this.state.countryCode == 0){
                postCredentials.email = "27" + this.state.formFields.email;
            } else {
                postCredentials.email = this.state.countryCode + "" + this.state.formFields.email;
            }
        }

        var authURL = Config.URL+"/verify-user";

        if(this.state.isUserValid){
            authURL = Config.URL+"/login";
        }

        fetch(authURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            //console.log("authUser response: ", response);
            this.setState({ isLoading: false });
            if(response.status){
                if(this.state.isUserValid){

                    shopApi.g4SingleEventPush('login',{
                        login_type: (this.state.active == 'login_by_mobile' ? 'Mobile' : 'Email'),
                        user_id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        mobile_number: response.data.contact,
                        email_address: response.data.email,
                        address: response.data.address,
                        city: response.data.city,
                    });

                    localStorage.setItem(Config.x_token, Config.setData(response.data.login_access_token));

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                    this.props.cartRefresh();
                    this.props.wishlistRefesh();
                    setTimeout(() => {
                        this.props.wishlistRefesh();
                    }, 1000);
                    setTimeout(() => {
                        this.props.cartRefresh();
                    }, 1500);
                    this.props.history.push("/");
                } else {
                    this.setState({ isUserValid: true });
                }
            } else {
                if(response.flag !== undefined && response.flag !== null && response.flag !== '' && response.flag === 'mobile_verification_required'){
                    this.setState({ flag: response.flag, errorVerificationMessage: response.message, openVerificationDialog: true, verificationEmailAuth: '' });
                }else if(response.flag !== undefined && response.flag !== null && response.flag !== '' && response.flag === 'user_mobile_verification_required'){
                    this.setState({ flag: response.flag, errorVerificationMessage: response.message, openVerificationDialog: true, verificationEmailAuth: '' });
                } else if(response.flag !== undefined && response.flag !== null && response.flag !== '' && response.flag === 'email_verification_required' ){
                    this.setState({ flag: response.flag, errorVerificationMessage: response.message, openVerificationDialog: true, verificationEmailAuth: response.email_verify_token });
                } else if(response.flag !== undefined && response.flag !== null && response.flag !== '' && response.flag === 'user_email_verification_required' ){
                    this.setState({ flag: response.flag, errorVerificationMessage: response.message, openVerificationDialog: true, verificationEmailAuth: response.forgot_password_token });
                } else {
                    toast.error(response.message);
                }
                localStorage.removeItem(Config.x_token);
                localStorage.removeItem(Config.web_user);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });

    }

    /**
     *
     */
    getAllCountries() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', Config.countryAuth);

        fetch(Config.URL+"/get-countries", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            //console.log("getAllCountries response: ", response);
            if(response.status){
                let countries = [];
                response.data.forEach((item, key) => {
                    countries.push({ name: "+" + item.country_code + "    " + item.name, value: item.country_code +"-"+item.id });
                });
                this.setState({ countries });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     *
     */
    sendVerification(){
        if(this.state.flag === "email_verification_required"){
            this.sendEmailVerification();
        }
        if(this.state.flag === "mobile_verification_required"){
            this.sendMobileVerification();
        }
        if(this.state.flag === "user_mobile_verification_required"){
            this.sendMobileVerification();
        }
        if(this.state.flag === "user_email_verification_required"){
            this.sendNewPasswordSetEmail();
        }
    }

    /**
     *
     */
    sendEmailVerification(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Authorization', this.state.verificationEmailAuth);

        let postCredentials = {...this.state.formFields};
        postCredentials.email = this.state.formFields.email;
        delete postCredentials.password;

        fetch(Config.URL+"/email-verification", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            //console.log("sendEmailVerification response: ", response);
            if(response.status){
                toast.success(response.message);
                this.setState({ verificationEmailAuth: '', openVerificationDialog: false});
            } else {
                this.setState({ openVerificationDialog: false });
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, verificationEmailAuth: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     *
     */
    sendMobileVerification(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);

        let postCredentials = {...this.state.formFields};
        postCredentials.mobile_number = this.state.countryCode + "" + this.state.formFields.email;
        delete postCredentials.email;
        delete postCredentials.password;

        fetch(Config.URL+"/send-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            //console.log("sendVerification response: ", response);
            if(response.status){
                toast.success(response.message);
                this.setState({ pageSubHeading: 'Verify OTP', openVerificationDialog: false, verificationAuth: response.OTP_token, startTimerLogin: true });
            } else {
                this.setState({ openVerificationDialog: false });
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     *
     */
    sendNewPasswordSetEmail(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_type', Config.device_type);
        headers.append('Authorization', this.state.verificationEmailAuth);

        fetch(Config.URL+"/send-set-password-email", {
            method: "POST",
            headers: headers,
            body: null
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.setState({ verificationEmailAuth: '', openVerificationDialog: false});
            } else {
                this.setState({ openVerificationDialog: false });
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, verificationEmailAuth: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     *
     */
    resendLoginOTP(){
        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('device_type', Config.device_type);

        let postCredentials = {...this.state.formFields};
        postCredentials.mobile_number = this.state.countryCode + "" + this.state.formFields.email;
        delete postCredentials.email;
        delete postCredentials.password;

        fetch(Config.URL+"/send-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            //console.log("sendVerification response: ", response);
            if(response.status){
                toast.success(response.message);
                this.setState({ pageSubHeading: 'Verify OTP', openVerificationDialog: false, verificationAuth: response.OTP_token, showResendOTPButtonLogin: false, startTimerLogin: false });
            } else {
                this.setState({ openVerificationDialog: false });
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateOTPForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateOTPForm() {
        var isReturn = true;
        let validationFormErrors = {
            isEmptyOTP: false
        };

        const otp = this.state.otp;

        if (otp === '' || otp === null) {
            validationFormErrors.isEmptyOTP = true;
            isReturn = false;
        }
        this.setState({ validationFormErrors });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    verifyOTP(){
        let isValidated = this.validateOTPForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('Authorization', this.state.verificationAuth);

        let postCredentials = {...this.state.formFields};
        postCredentials.mobile_number = this.state.countryCode + "" + this.state.formFields.email;
        postCredentials.otp = this.state.otp;
        postCredentials.flag = this.state.flag;
        delete postCredentials.email;
        delete postCredentials.password;

        fetch(Config.URL+"/verify-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                if(this.state.flag === 'user_mobile_verification_required'){
                    if(response.data.forgot_password_token !== undefined && response.data.forgot_password_token !== null && response.data.forgot_password_token !== ''){
                        this.props.history.push("/account/set-password/"+response.data.forgot_password_token);
                    } else {
                        this.setState({ pageSubHeading: 'Login', otp: '' });
                    }
                } else {
                    this.setState({ pageSubHeading: 'Login', otp: '' });
                }
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, otp: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }

    /**
     * [validateRegisterOTPForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateRegisterOTPForm() {
        var isReturn = true;
        let validationRegisterFormErrors = {
            isEmptyOTP: false
        };

        const otp = this.state.registerOTP;

        if (otp === '' || otp === null) {
            validationRegisterFormErrors.isEmptyOTP = true;
            isReturn = false;
        }
        this.setState({ validationRegisterFormErrors });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    verifyOTPRegister(){
        let isValidated = this.validateRegisterOTPForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('Authorization', this.state.registerOTPToken);

        let postCredentials = {};
        postCredentials.country = this.state.countryCodeRegisterRemember.split("-")[1];
        var countryCode = this.state.countryCodeRegister;
        if(!this.state.countryCodeRegister || this.state.countryCodeRegister == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postCredentials.mobile_number = countryCode + "" + this.state.registerFormFields.mobile_number;
        postCredentials.otp = this.state.registerOTP;

        //postCredentials.mobile_number = "919081402323";

        fetch(Config.URL+"/verify-new-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.setState({ pageRegisterSubHeading: 'Register', registerOTP: '', registerOTPToken: '' });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, otp: '' });
            //console.log("err: ", err);
            toast.error(err);
        });
    }


    /**
     * [validateEmailRegisterForm validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateEmailRegisterForm() {
        var isReturn = true;
        let validationRegisterFormErrors = {
            isEmptyOTP: false
        };

        const otp = this.state.registerOTP;

        if (otp === '' || otp === null) {
            validationRegisterFormErrors.isEmptyOTP = true;
            isReturn = false;
        }
        this.setState({ validationRegisterFormErrors });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    verifyEmailRegister(){
        let isValidated = this.validateEmailRegisterForm();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('Authorization', this.state.registerOTPToken);

        let postCredentials = {};
        postCredentials.email_address = this.state.registerFormFields.email_address;
        postCredentials.otp = this.state.registerOTP;

        fetch(Config.URL+"/verify-new-email", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.setState({ pageRegisterSubHeading: 'Register', registerOTP: '', registerOTPToken: '' });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            this.setState({ openVerificationDialog: false, otp: '' });
            toast.error(err);
        });
    }

    /**
     * [validateRegisterMobileNumber validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
     validateRegisterMobileNumber() {
        var isReturn = true;
        let isEmptyRegisterExistingMobile = false;

        const mobile_number = this.state.registerMobileNumberUpdate;

        if (mobile_number === '' || mobile_number === null) {
            isEmptyRegisterExistingMobile = true;
            isReturn = false;
        }
        this.setState({ isEmptyRegisterExistingMobile });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    updateNewMobileNumber(){
        let isValidated = this.validateRegisterMobileNumber();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('Authorization', this.state.registerOTPToken);

        let postMobileNumber = {};
        var countryCode = this.state.countryCodeRegister;
        if(countryCode == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postMobileNumber.mobile_number = countryCode + "" + this.state.registerMobileNumberUpdate;
        fetch(Config.URL+"/update-mobile", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postMobileNumber)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.setState({
                    isLoading: false,
                    startTimerRegister: true,
                    isMobileNumberChangeRequested: false,
                    registerFormFields : {
                        mobile_number : this.state.registerMobileNumberUpdate
                    }
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            toast.error(err);
        });
    }

    /**
     * [validateRegisterEmail validate form inputs]
     * @return {Boolean} [Return true/false based on validaton]
     */
    validateRegisterEmail() {
        var isReturn = true;
        let isEmptyRegisterExistingEmail = false;
        let isEmptyRegisterExistingisValidEmailAddress = false;

        const email_address = this.state.registerEmailUpdate;

        if (email_address === '' || email_address === null) {
            isEmptyRegisterExistingEmail = true;
            isReturn = false;
        }
        if (email_address !== '' && !this.isValidEmailAddress(email_address)) {
            isEmptyRegisterExistingisValidEmailAddress = true;
            isReturn = false;
        }
        this.setState({ isEmptyRegisterExistingEmail });
        this.setState({ isEmptyRegisterExistingisValidEmailAddress });
        return isReturn;
    }

    /**
     *
     * @returns
     */
    updateNewEmail(){
        let isValidated = this.validateRegisterEmail();
        if (!isValidated) {
            return;
        }

        this.setState({ isLoading: true });
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('device_token', Config.device_token);
        headers.append('Authorization', this.state.registerOTPToken);

        let postEmail = {};
        postEmail.email_address = this.state.registerEmailUpdate;
        fetch(Config.URL+"/update-new-email", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postEmail)
        })
        .then(response => response.json())
        .then(response => {
            this.setState({ isLoading: false });
            if(response.status){
                toast.success(response.message);
                this.setState({
                    isLoading: false,
                    startTimerRegister: true,
                    isEmailChangeRequested: false,
                    registerFormFields : {
                        email_address : this.state.registerEmailUpdate
                    }
                });
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            toast.error(err);
        });
    }

    /**
     *
     */
    resendRegisterOTP() {
        this.setState({ isLoading: true });

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        let postCredentials = {};
        var countryCode = this.state.countryCodeRegister;
        if(countryCode == 0){
            countryCode = this.state.countryCodeRegisterRemember.split("-")[0];
        }
        postCredentials.mobile_number = countryCode + "" + this.state.registerFormFields.mobile_number;

        fetch(Config.URL+"/send-otp", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(postCredentials)
        })
        .then(response => response.json())
        .then(response => {
            //console.log("resendRegisterOTP response: ", response);
            this.setState({ isLoading: false });
            if(response.status){
                this.setState({ startTimerRegister: false, showResendOTPButton: false });
                this.setState({ registerOTPToken: response.OTP_token, pageRegisterSubHeading: 'Verify OTP' });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            //console.log("err: ", err);
            toast.error(err);
        });

    }

    /**
     *
     */
    closeVerificationDialog(){
        this.setState({ openVerificationDialog : false });
    }

    /**
     *
     */
    setLoginEnable(){
        this.setState({ pageSubHeading : 'Login', showResendOTPButtonLogin: false, startTimerLogin: false });
    }

    /**
     *
     */
    setRegisterEnable(){
        this.setState({ pageRegisterSubHeading : 'Register', showResendOTPButton: false, startTimerRegister: false });
    }

    /**
     *
     */
    setNewRegisterEnable(){
        this.setState({ pageRegisterSubHeading : 'RegisterNew', showResendOTPButton: false, startTimerRegister: false });
    }

    /**
     * [handleSetValue set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValue = e => {
        e.persist();
        this.setState(prevState => ({
            formFields: { ...prevState.formFields, [e.target.name]: e.target.value }
        }))
    }

    /**
     * [handleSetValueRegister set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValueRegister = e => {
        e.persist();
        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, [e.target.name]: e.target.value }
        }))
    }

    /**
     * [handleSetValueRegisterMobile set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValueRegisterMobile = e => {
        e.persist();
        this.setState({ registerMobileNumberUpdate : e.target.value });
    }

    /**
     * [handleSetValueRegisterEmail set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValueRegisterEmail = e => {
        e.persist();
        this.setState({ registerEmailUpdate : e.target.value });
    }

    /**
     * [handleSetValuePassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
     handleSetValuePassword = e => {
        if(!this.isValidPassword(e.password)){
            this.setState({ passwordScore: 2 });
        }
        if(this.isValidPassword(e.password)){
            this.setState({ passwordScore: 5 });
        }
        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, ['password']: e.password }
        }));
        // if(e.score === 4 && e.isValid){
        //     this.setState(prevState => ({
        //         registerFormFields: { ...prevState.registerFormFields, ['password']: e.password }
        //     }));
        // }
    }

    /**
     * [handleSetValueCPassword set input value to the global variable]
     * @param {Object} e [object of textbox]
     */
    handleSetValueCPassword = e => {
        if(!this.isValidPassword(e.password)){
            this.setState({ cPasswordScore: 2 });
        }
        if(this.isValidPassword(e.password)){
            this.setState({ cPasswordScore: 4 });
        }
        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, ['confirm_password']: e.password }
        }));
        // if(e.score === 4 && e.isValid){
        //    this.setState(prevState => ({
        //        registerFormFields: { ...prevState.registerFormFields, ['confirm_password']: e.c_password }
        //    }))
        // }
    }

    /**
     * [handleSubmitForm submit form function]
     * @param  {Object} event [event object of submitted form]
     * @return {}
     */
    handleSubmitForm(event) {
        event.preventDefault();
    }

    /**
     * [setRemember set remember me yes/no]
     * @return {}
     */
    setRemember() {
        this.setState({ isRememberMe: !this.state.isRememberMe });
        if (!this.state.isRememberMe) {
            localStorage.setItem('isRememberMe', "true");
        } else {
            localStorage.removeItem('isRememberMe');
        }
    }

    handleClose = () => {
        this.setState({ isLoading: false });
    };

    /**
     *
     * @param {*} response
     */
    responseFacebook = (response) => {
        if(response.id === undefined){
            toast.error("'Temporary Error (502)' Login with Facebook is not working. Please try after some time!");
        } else {
            this.setState({ isLoading: true });

            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);

            let socialDetails = {
                "first_name": (response.name.split(" ")[0] !== undefined && response.name.split(" ")[0] !=='' ? response.name.split(" ")[0] : ''),
                "last_name": (response.name.split(" ")[1] !== undefined && response.name.split(" ")[1] !=='' ? response.name.split(" ")[1] : ''),
                "email": (response.email !== undefined && response.email !== '' ? response.email : ''),
                "gender": "",
                "mobile_number": "",
                "id": response.id,
                "profile_image": (response.picture.data.url !== undefined && response.picture.data.url !=='' ? response.picture.data.url : ''),
                "social_provider": "facebook"
            };

            fetch(Config.URL+"/social-login", {
                method: "POST",
                headers: headers,
                body: JSON.stringify(socialDetails)
            })
            .then(response => response.json())
            .then(response => {
                //console.log("responseFacebook response: ", response);
                this.setState({ isLoading: false });
                if(response.status){
                    localStorage.setItem(Config.x_token, Config.setData(response.data.login_access_token));

                    let currentPic = '';

                    //shopApi.g4LoginEventPush('Login', 'Facebook', response.data.id);

                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }

                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                    this.props.cartRefresh();
                    this.props.wishlistRefesh();
                    setTimeout(() => {
                        this.props.wishlistRefesh();
                    }, 1000);
                    setTimeout(() => {
                        this.props.cartRefresh();
                    }, 1500);
                    this.props.history.push("/");
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                //console.log("responseFacebook err: ", err);
                toast.error(err);
            });
        }
    }

    /**
     *
     * @param {*} response
     */
    responseGoogleSuccess = (response) => {
        if(response.profileObj === undefined){
            toast.error("'Temporary Error (502)' Login with Gmail is not working. Please try after some time!");
        } else {
            this.setState({ isLoading: true });

            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('device_token', Config.device_token);
            headers.append('device_type', Config.device_type);

            let socialDetails = {
                "first_name": (response.profileObj.name.split(" ")[0] !== undefined ? response.profileObj.name.split(" ")[0] : ''),
                "last_name": (response.profileObj.name.split(" ")[1] !== undefined ? response.profileObj.name.split(" ")[1] : ''),
                "email": (response.profileObj.email !== undefined ? response.profileObj.email : ''),
                "gender": "",
                "mobile_number": "",
                "id": response.profileObj.googleId,
                "profile_image": (response.profileObj.imageUrl !== undefined && response.profileObj.imageUrl !== '' ? response.profileObj.imageUrl : ''),
                "social_provider": "google"
            };

            fetch(Config.URL+"/social-login", {
                method: "POST",
                headers: headers,
                body: JSON.stringify(socialDetails)
            })
            .then(response => response.json())
            .then(response => {
                //console.log("responseFacebook response: ", response);
                this.setState({ isLoading: false });
                if(response.status){
                    localStorage.setItem(Config.x_token, Config.setData(response.data.login_access_token));

                    //shopApi.g4LoginEventPush('Login', 'Google', response.data.id);

                    let currentPic = '';
                    if(response.data.profile_image !== ''){
                        if(this.isValidURL(response.data.profile_image)){
                            currentPic = response.data.profile_image;
                        } else {
                            currentPic = Config.awsAssetsURL + "/filesources/profile_image/" + response.data.id + "/" + response.data.profile_image;
                        }
                    }
                    let userJSON = {
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        profile_percentage: response.data.profile_percentage,
                        profile_image: currentPic
                    }
                    localStorage.setItem(Config.web_user, Config.setData(JSON.stringify(userJSON)));
                    setTimeout(() => {
                        this.props.wishlistRefesh();
                    }, 1000);
                    setTimeout(() => {
                        this.props.cartRefresh();
                    }, 1500);
                    this.props.history.push("/");
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                //console.log("responseFacebook err: ", err);
                toast.error(err);
            });
        }
    }

    /**
     *
     * @param {*} response
     */
    responseGoogle = (response) => {
        //console.log("Fail: ",response);
    }

    /**
     *
     * @param {*} event
     */
    handleSetTermsAgreed = (event) => {
        if (event.target.checked) {
            this.setState({ termsAndConditionsAgreed: true });
        } else {
            this.setState({ termsAndConditionsAgreed: false });
        }
    };

    /**
     *
     * @param {*} type
     */
    changeLoginPasswordType(type){
        this.setState({ type });
    }

    /**
     *
     * @param {*} register_type
     */
    changeRegisterPasswordType(register_type){
        this.setState({ register_type });
    }

    /**
     *
     * @param {*} register_type_c
     */
    changeRegisterCPasswordType(register_type_c){
        this.setState({ register_type_c });
    }

    /**
     *
     * @param {*} place
     */
    handleSetAddress(place){
        var address = "", city = "", state = "", zip = "", country = "";
        for (var i = 0; i < place.address_components.length; i++) {
            var addr = place.address_components[i];
            // check if this entry in address_components has a type of country
            if (addr.types[0] == 'country')
                country = addr.long_name;
            else if (addr.types[0] == 'street_address') // address 1
                address = address + addr.long_name;
            else if (addr.types[0] == 'establishment')
                address = address + addr.long_name;
            else if (addr.types[0] == 'route')  // address 2
                address = address + addr.long_name;
            else if (addr.types[0] == 'sublocality_level_1')  // address 2
                address = (address !== '' ? address + ", "+addr.long_name : address + addr.long_name );
            else if (addr.types[0] == 'postal_code')       // Zip
                zip = addr.short_name;
            else if (addr.types[0] == ['administrative_area_level_1'])       // State
                state = addr.long_name;
            else if (addr.types[0] == ['locality'])       // City
                city = addr.long_name;
        }

        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, 'address': place.formatted_address }
        }));

        this.setState(prevState => ({
            registerFormFields: { ...prevState.registerFormFields, 'city': city }
        }));
    }

    /**
     *
     */
    setEnableChangeMobileNumber(){
        this.setState({ isMobileNumberChangeRequested: !this.state.isMobileNumberChangeRequested });
    }

    /**
     *
     */
    setEnableChangeEmail(){
        this.setState({ isEmailChangeRequested: !this.state.isEmailChangeRequested });
    }

    /**
     *
     * @returns
     */
    render() {

        let {
            password1Length,
            contains1Numbers,
            isUpper1Case,
            contains1Symbols,
            password2Length,
            contains2Numbers,
            isUpper2Case,
            contains2Symbols
        } = this.state

        const renderTimeRegister = ({ remainingTime }) => {
            if (remainingTime === 0) {
                this.setState({ startTimerRegister: false, showResendOTPButton: true });
                return;
            }

            return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
            );
        };

        const renderTimeLogin = ({ remainingTime }) => {
            //console.log(remainingTime);
            if (remainingTime === 0) {
                this.setState({ startTimerLogin: false, showResendOTPButtonLogin: true });
                return;
            }

            return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
            );
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Login | ${theme.name}`}</title>
                </Helmet>

                <Backdrop className="backdrop" open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <PageHeader header="Login or Register your account" breadcrumb={breadcrumb} />

                {this.state.flag !== '' &&
                    <Dialog
                        open={this.state.openVerificationDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        //onClose={() => { this.closeVerificationDialog() }}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{"Your account is not verified."}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.errorVerificationMessage}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={() => { this.closeVerificationDialog() }} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => { this.sendVerification() }} color="primary">
                            Verify Now
                        </Button>
                        </DialogActions>
                    </Dialog>
                }

                <div className="block">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex">


                                {this.state.pageSubHeading == 'Login' &&
                                    <div className="card flex-grow-1 mb-md-0 height-500">
                                        <div className="card-body">
                                            <h3 className="card-title">{this.state.pageSubHeading}</h3>
                                                <div className="prsntg_btns">
                                                    <span onClick={()=> { this.changeLoginType('login_by_email') }} className={(this.state.active === 'login_by_email' ? 'button active' : 'button')}>Login By Email</span>
                                                    <span onClick={()=> { this.changeLoginType('login_by_mobile') }} className={(this.state.active === 'login_by_mobile' ? 'button active' : 'button')}>Login By Mobile</span>
                                                </div>

                                                <form onSubmit={this.handleSubmitForm}>
                                                {this.state.active === "login_by_email" ?
                                                <div className="form-group">
                                                    <label htmlFor="login-email">Email address</label>
                                                    <input
                                                        type="text"
                                                        value={this.state.formFields.email}
                                                        id="email"
                                                        onChange={this.handleSetValue}
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Please enter email address"
                                                    />
                                                    {this.state.validationFormErrors.isEmptyEmail ?
                                                    <span className="errorInput">Please enter email address</span>
                                                    : null
                                                    }
                                                    {this.state.validationFormErrors.isNotValidEmail ?
                                                    <span className="errorInput">Please enter a valid email address</span>
                                                    : null
                                                    }
                                                </div>
                                                :
                                                <div className="form-group">
                                                    <label htmlFor="login-email">Mobile number</label>
                                                    <div className="mobileContainer">
                                                        <div className="countrySection">
                                                        <SelectSearch
                                                            options={this.state.countries}
                                                            name="country"
                                                            search
                                                            value={[this.state.countryCodeRemember]}
                                                            filterOptions={this.fuzzySearch}
                                                            onChange={(event) => { this.setCountryCode(event) }}
                                                            emptyMessage="No matched country found in search criteria"
                                                        />
                                                        </div>
                                                        <div className="mobileNumberSection">
                                                            <input
                                                                type="number"
                                                                value={this.state.formFields.email}
                                                                id="mobile_number"
                                                                onChange={this.handleSetValue}
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Please enter mobile number"
                                                            />
                                                        </div>
                                                        {this.state.validationFormErrors.isEmptyMobileNumber ?
                                                        <span className="errorInput">Please enter mobile number</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>
                                                }

                                                {this.state.isUserValid &&
                                                <div className="form-group">
                                                    <label htmlFor="login-password">Password</label>
                                                    <div className="pass-wrapper">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Please enter password"
                                                            value={this.state.formFields.password}
                                                            id="password"
                                                            onChange={this.handleSetValue}
                                                            type={this.state.type}
                                                            name="password"
                                                        />
                                                        {this.state.type == 'password' ?
                                                            <i onClick={() => { this.changeLoginPasswordType('text') }} className="fa fa-eye"></i>
                                                        :
                                                            <i onClick={() => { this.changeLoginPasswordType('password') }} className="fa fa-eye-slash"></i>
                                                        }
                                                    </div>
                                                    {/* <PasswordStrengthBar
                                                        password={this.state.formFields.password}
                                                    /> */}
                                                    {this.state.validationFormErrors.isEmptyPassword ?
                                                        <span className="errorInput">Please enter password</span>
                                                    : null
                                                    }
                                                    {this.state.validationFormErrors.isNotValidLengthPassword ?
                                                        <span className="errorInput">Password length must be at least 8 chars</span>
                                                    : null
                                                    }
                                                </div>
                                                }
                                                <div className="form-group">
                                                    <small className="form-text text-muted">
                                                        <Link hrefLang={"en"}  to="/account/forgot-password">Forgot Password?</Link>
                                                    </small>
                                                </div>


                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <span className="form-check-input input-check">
                                                            <span className="input-check__body">
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-check__input"
                                                                    onChange={() => { this.setRemember() }}
                                                                    id="rememberMe"
                                                                    checked={this.state.isRememberMe}
                                                                />
                                                                <span className="input-check__box" />
                                                                <Check9x7Svg className="input-check__icon" />
                                                            </span>
                                                        </span>
                                                        <label className="form-check-label" htmlFor="rememberMe">
                                                            Remember Me
                                                        </label>
                                                    </div>
                                                </div>
                                                <button style={{float: 'left', width: '100%', textAlign: 'center', borderRadius: 5}} onClick={() => { this.authUser() }}  type="submit" className="btn btn-primary mt-1 mt-md-2 mt-lg-2">
                                                    Login
                                                </button>
                                            </form>
                                            <span className="mt-3" style={{ float: 'left', width: '100%', textAlign: 'center'}}>Or Login With</span>
                                            <div className="row">
                                                <div className="col-md-6 d-flex mt-2 faebook-login-button">
                                                    <FacebookLogin
                                                        appId={Config.FB_APP_ID}
                                                        autoLoad={false}
                                                        fields="name,email,picture"
                                                        scope="public_profile"
                                                        callback={this.responseFacebook}
                                                        cssClass="facebook-button-class"
                                                        textButton="Facebook"
                                                    />
                                                </div>
                                                <div className="col-md-6 d-flex mt-2 google-login-button">
                                                    <GoogleLogin
                                                        clientId={Config.GOOGLE_APP_ID}
                                                        buttonText="Google"
                                                        onSuccess={this.responseGoogleSuccess}
                                                        onFailure={this.responseGoogle}
                                                        cookiePolicy={'single_host_origin'}
                                                        className="btn-lg btn-outline"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }



                                {this.state.pageSubHeading == 'Verify OTP' &&
                                    <div className="card flex-grow-1 mb-md-0 height-300">
                                        <div className="card-body">
                                            <h3 className="card-title">{this.state.pageSubHeading}</h3>
                                            <p>We have sent an OTP to: <b>{this.state.formFields.email}</b></p>
                                            <form onSubmit={this.handleSubmitForm}>
                                                <div className="form-group">
                                                    <label htmlFor="otp">Enter verification code</label>
                                                    <OtpInput
                                                        id="otp"
                                                        value={this.state.otp}
                                                        onChange={this.handleSetOTP}
                                                        numInputs={6}
                                                        inputStyle={{width: 50, height: 50, borderRadius: 10}}
                                                        separator={<span>&nbsp;-&nbsp;</span>}
                                                    />
                                                    {this.state.validationFormErrors.isEmptyOTP ?
                                                        <span className="errorInput">Please enter OTP</span>
                                                    : null
                                                    }
                                                </div>
                                                <button onClick={() => { this.verifyOTP() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                    Verify Now
                                                </button> &nbsp;&nbsp;&nbsp;
                                                <button onClick={() => { this.setLoginEnable() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                    Cancel
                                                </button> &nbsp;&nbsp;&nbsp;

                                                {this.state.showResendOTPButtonLogin &&
                                                    <button onClick={() => { this.resendLoginOTP() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Resend OTP
                                                    </button>
                                                }
                                                {this.state.startTimerLogin &&
                                                <div className="timer-wrapper">
                                                    <CountdownCircleTimer
                                                    size={60}
                                                    strokeWidth={5}
                                                    duration={this.state.timerDuration}
                                                    isPlaying={this.state.startTimerLogin}
                                                    colors={[["#000", 0.33], ["#000", 0.33], ["#FF0000"]]}
                                                    //onComplete={() => [false, 1000]}
                                                    >
                                                    {renderTimeLogin}
                                                    </CountdownCircleTimer>
                                                </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                }


                            </div>

                            <div className="col-md-6 d-flex mt-4 mt-md-0">
                                {this.state.pageRegisterSubHeading == 'RegisterNew' &&
                                    <div className="card flex-grow-1 mb-0">
                                        <div className="card-body">
                                            <h3 className="card-title">Register</h3>
                                            <form onSubmit={this.handleSubmitForm}>
                                                <div className="form-group">
                                                    <label htmlFor="register-first-name">Select Country</label>
                                                    <div className="mobileContainer">
                                                        <div className="countrySection" style={{width: '100%'}}>
                                                            <SelectSearch
                                                                options={this.state.countries}
                                                                name="register-country"
                                                                search
                                                                value={[this.state.countryCodeRegisterRemember]}
                                                                filterOptions={this.fuzzySearchRegister}
                                                                onChange={(event) => { this.setCountryCodeRegister(event) }}
                                                                emptyMessage="No matched country found in search criteria"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.countryCodeRegisterRemember.split("-")[1] === '202' &&
                                                    <div className="form-group">
                                                        <label htmlFor="register-first-name">Mobile Number</label>
                                                            <input
                                                                type="number"
                                                                id="register-mobile-number"
                                                                onChange={this.handleSetValueRegister}
                                                                name="mobile_number"
                                                                className="form-control"
                                                                placeholder="Please enter mobile number"
                                                                defaultValue={this.state.registerFormFields.mobile_number}
                                                            />
                                                            {this.state.validationRegisterFormErrors.isEmptyMobileNumber ?
                                                            <span className="errorInput">Please enter mobile number</span>
                                                            : null
                                                            }
                                                    </div>
                                                }
                                                {this.state.countryCodeRegisterRemember.split("-")[1] !== '202' &&
                                                    <div className="form-group">
                                                        <label htmlFor="register-email">Email address</label>
                                                        <input
                                                            id="register-email"
                                                            type="email"
                                                            name="email_address"
                                                            onChange={this.handleSetValueRegister}
                                                            className="form-control"
                                                            placeholder="Please enter email address"
                                                            defaultValue={this.state.registerFormFields.email_address}
                                                        />
                                                        {this.state.validationRegisterFormErrors.isEmptyEmail ?
                                                        <span className="errorInput">Please enter email address</span>
                                                        : null
                                                        }
                                                        {this.state.validationRegisterFormErrors.isValidEmailAddress ?
                                                        <span className="errorInput">Please enter a valid email address</span>
                                                        : null
                                                        }
                                                    </div>
                                                }
                                                <button style={{float: 'left', width: '100%', textAlign: 'center', borderRadius: 5}} onClick={() => { this.verifyNewUser() }} type="submit" className="btn btn-primary mt-1 mt-md-2 mt-lg-1">
                                                    Verify
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                }

                                {this.state.pageRegisterSubHeading == 'Register' &&
                                    <div className="card flex-grow-1 mb-0">
                                        <div className="card-body">
                                            <h3 className="card-title">{this.state.pageRegisterSubHeading}</h3>
                                            <form onSubmit={this.handleSubmitForm}>
                                                <div className="form-group">
                                                    <label htmlFor="register-first-name">First name</label>
                                                    <input
                                                        id="register-first-name"
                                                        type="text"
                                                        name="first_name"
                                                        onChange={this.handleSetValueRegister}
                                                        className="form-control"
                                                        placeholder="Please enter first name"
                                                        defaultValue={this.state.registerFormFields.first_name}
                                                    />
                                                    {this.state.validationRegisterFormErrors.isEmptyFirstName ?
                                                    <span className="errorInput">Please enter first name</span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="register-last-name">Last name</label>
                                                    <input
                                                        id="register-last-name"
                                                        type="text"
                                                        name="last_name"
                                                        onChange={this.handleSetValueRegister}
                                                        className="form-control"
                                                        placeholder="Please enter last name"
                                                        defaultValue={this.state.registerFormFields.last_name}
                                                    />
                                                    {this.state.validationRegisterFormErrors.isEmptyLastName ?
                                                    <span className="errorInput">Please enter last name</span>
                                                    : null
                                                    }
                                                </div>

                                                {this.state.countryCodeRegisterRemember.split("-")[1] !== '202' &&
                                                    <div className="form-group">
                                                        <label htmlFor="register-first-name">Mobile Number</label>
                                                            <input
                                                                type="number"
                                                                id="register-mobile-number"
                                                                onChange={this.handleSetValueRegister}
                                                                name="mobile_number"
                                                                className="form-control"
                                                                placeholder="Please enter mobile number"
                                                                defaultValue={this.state.registerFormFields.mobile_number}
                                                            />
                                                            {this.state.validationRegisterFormErrors.isEmptyMobileNumber ?
                                                            <span className="errorInput">Please enter mobile number</span>
                                                            : null
                                                            }
                                                    </div>
                                                }
                                                {this.state.countryCodeRegisterRemember.split("-")[1] === '202' &&
                                                    <div className="form-group">
                                                        <label htmlFor="register-email">Email address</label>
                                                        <input
                                                            id="register-email"
                                                            type="email"
                                                            name="email_address"
                                                            onChange={this.handleSetValueRegister}
                                                            className="form-control"
                                                            placeholder="Please enter email address"
                                                            defaultValue={this.state.registerFormFields.email_address}
                                                        />
                                                        {this.state.validationRegisterFormErrors.isEmptyEmail ?
                                                        <span className="errorInput">Please enter email address</span>
                                                        : null
                                                        }
                                                        {this.state.validationRegisterFormErrors.isValidEmailAddress ?
                                                        <span className="errorInput">Please enter a valid email address</span>
                                                        : null
                                                        }
                                                    </div>
                                                }

                                                {/* <div className="form-group">
                                                    <label htmlFor="register-mobile-number">Mobile number</label>
                                                    <div className="mobileContainer">
                                                        <div className="countrySection">
                                                        <SelectSearch
                                                            options={this.state.countries}
                                                            name="register-country"
                                                            search
                                                            value={[this.state.countryCodeRegisterRemember]}
                                                            filterOptions={this.fuzzySearchRegister}
                                                            onChange={(event) => { this.setCountryCodeRegister(event) }}
                                                            emptyMessage="No matched country found in search criteria"
                                                        />
                                                        </div>
                                                        <div className="mobileNumberSection">
                                                            <input
                                                                type="number"
                                                                id="register-mobile-number"
                                                                onChange={this.handleSetValueRegister}
                                                                name="mobile_number"
                                                                className="form-control"
                                                                placeholder="Please enter mobile number"
                                                                defaultValue={this.state.registerFormFields.mobile_number}
                                                            />
                                                        </div>
                                                        {this.state.validationRegisterFormErrors.isEmptyMobileNumber ?
                                                        <span className="errorInput">Please enter mobile number</span>
                                                        : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="register-email">Email address</label>
                                                    <input
                                                        id="register-email"
                                                        type="email"
                                                        name="email_address"
                                                        onChange={this.handleSetValueRegister}
                                                        className="form-control"
                                                        placeholder="Please enter email address"
                                                        defaultValue={this.state.registerFormFields.email_address}
                                                    />
                                                    {this.state.validationRegisterFormErrors.isEmptyEmail ?
                                                    <span className="errorInput">Please enter email address</span>
                                                    : null
                                                    }
                                                    {this.state.validationRegisterFormErrors.isValidEmailAddress ?
                                                    <span className="errorInput">Please enter a valid email address</span>
                                                    : null
                                                    }
                                                </div> */}
                                                <div className="form-group">
                                                    <label htmlFor="register-address">Address</label>
                                                    <Autocomplete
                                                        onChange={this.handleSetValueRegister}
                                                        className="form-control"
                                                        apiKey={Config.googleAPIKey}
                                                        id="address"
                                                        name="address"
                                                        onPlaceSelected={(place, inputRef, autocomplete) => {
                                                            this.handleSetAddress(place);
                                                        }}
                                                        placeholder="Please enter address"
                                                        defaultValue={this.state.registerFormFields.address}
                                                        options={{
                                                            types: ["geocode"],
                                                            //componentRestrictions: { country: "in" },
                                                        }}
                                                    />
                                                    {/* <input
                                                        id="register-address"
                                                        type="text"
                                                        name="address"
                                                        onChange={this.handleSetValueRegister}
                                                        className="form-control"
                                                        placeholder="Please enter address"
                                                    /> */}
                                                    {this.state.validationRegisterFormErrors.isEmptyAddress ?
                                                    <span className="errorInput">Please enter address</span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="register-city">City</label>
                                                    <input
                                                        id="register-city"
                                                        name="city"
                                                        type="text"
                                                        onChange={this.handleSetValueRegister}
                                                        className="form-control"
                                                        defaultValue={this.state.registerFormFields.city}
                                                        placeholder="Please enter city"
                                                    />
                                                    {this.state.validationRegisterFormErrors.isEmptyCity ?
                                                    <span className="errorInput">Please enter city</span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="register-password">Password</label>
                                                    <input
                                                        id="register-password"
                                                        type={this.state.register_type}
                                                        name="password"
                                                        onChange={this.handleChangeSetRegisterPassword}
                                                        className="form-control"
                                                        placeholder="Please enter password"
                                                    />
                                                    {/* <ReactPasswordStrength
                                                        className=""
                                                        minLength={8}
                                                        minScore={0}
                                                        scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                                        changeCallback={this.handleSetValuePassword}
                                                        inputProps={{ type: this.state.register_type, name: "password", autoComplete: "off", className: "form-control", placeholder: "Please enter password" }}
                                                        /> */}
                                                    <div className="pass-wrapper pass-wrapper-signup-password">
                                                        {this.state.register_type == 'password' ?
                                                            <i onClick={() => { this.changeRegisterPasswordType('text') }} className="fa fa-eye"></i>
                                                        :
                                                            <i onClick={() => { this.changeRegisterPasswordType('password') }} className="fa fa-eye-slash"></i>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div style={{fontSize: 14}} className={password1Length ? 'green' : 'gray'}>Contains min 8 characters</div>
                                                        <div style={{fontSize: 14}} className={contains1Numbers ? 'green' : 'gray'}>Contains 1 number</div>
                                                        <div style={{fontSize: 14}} className={isUpper1Case ? 'green' : 'gray'}>Contains 1 UpperCase</div>
                                                        <div style={{fontSize: 14}} className={contains1Symbols ? 'green' : 'gray'}>Contains 1 Symbol</div>
                                                    </div>

                                                    {/* <p style={{fontSize: 12, color: 'blue'}}>Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required</p> */}
                                                    {/* <PasswordStrengthBar
                                                        password={this.state.registerFormFields.password}
                                                        minLength="8"
                                                    /> */}
                                                    {this.state.validationRegisterFormErrors.isEmptyPassword ?
                                                    <span className="errorInput">Please enter password</span>
                                                    : null
                                                    }
                                                    {this.state.validationRegisterFormErrors.isNotValidPassword ?
                                                    <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required<br></br></span>
                                                    : null
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="register-confirm">Confirm Password</label>
                                                    <input
                                                        id="register-c-password"
                                                        type={this.state.register_type_c}
                                                        name="confirm_password"
                                                        onChange={this.handleChangeSetRegisterCPassword}
                                                        className="form-control"
                                                        placeholder="Please enter confirm password"
                                                    />
                                                    {/* <ReactPasswordStrength
                                                    className=""
                                                    minLength={8}
                                                    minScore={0}
                                                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                                                    changeCallback={this.handleSetValueCPassword}
                                                    inputProps={{ type: this.state.register_type_c, name: "confirm_password", autoComplete: "off", className: "form-control", placeholder: "Please enter confirm password" }}
                                                    /> */}
                                                    <div className="pass-wrapper pass-wrapper-signup-password">
                                                        {this.state.register_type_c == 'password' ?
                                                            <i onClick={() => { this.changeRegisterCPasswordType('text') }} className="fa fa-eye"></i>
                                                        :
                                                            <i onClick={() => { this.changeRegisterCPasswordType('password') }} className="fa fa-eye-slash"></i>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div style={{fontSize: 14}} className={password2Length ? 'green' : 'gray'}>Contains min 8 characters</div>
                                                        <div style={{fontSize: 14}} className={contains2Numbers ? 'green' : 'gray'}>Contains 1 number</div>
                                                        <div style={{fontSize: 14}} className={isUpper2Case ? 'green' : 'gray'}>Contains 1 UpperCase</div>
                                                        <div style={{fontSize: 14}} className={contains2Symbols ? 'green' : 'gray'}>Contains 1 Symbol</div>
                                                    </div>
                                                    {/* <PasswordStrengthBar
                                                        password={this.state.registerFormFields.confirm_password}
                                                        minLength="8"
                                                    /> */}
                                                    {this.state.validationRegisterFormErrors.isEmptyCPassword ?
                                                    <span className="errorInput">Please enter confirm password <br></br></span>
                                                    : null
                                                    }
                                                    {this.state.validationRegisterFormErrors.isNotSameBothPassword ?
                                                    <span className="errorInput">Password and confirm password must be same<br></br></span>
                                                    : null
                                                    }
                                                    {this.state.validationRegisterFormErrors.isNotValidCPassword ?
                                                    <span className="errorInput">Password length must be at least 8 chars and a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character [0-9] is required and a special char is required<br></br></span>
                                                    : null
                                                    }
                                                </div>

                                                <div className="checkout__agree form-group" style={{marginBottom: 20}}>
                                                    <div className="form-check">
                                                        <span className="form-check-input input-check">
                                                            <span className="input-check__body">
                                                                <input onChange={this.handleSetTermsAgreed} checked={this.state.termsAndConditionsAgreed} className="input-check__input" type="checkbox" id="checkout-terms" name="checkout_terms" />
                                                                <span className="input-check__box" />
                                                                <Check9x7Svg className="input-check__icon" />
                                                            </span>
                                                        </span>
                                                        <label className="form-check-label" htmlFor="checkout-terms">
                                                            I have read and agree to the website&nbsp;
                                                            <Link hrefLang={"en"}  to="/terms-conditions">Terms &amp; Conditions</Link>
                                                        </label>
                                                    </div>
                                                </div>

                                                <button disabled={!this.state.termsAndConditionsAgreed} style={{float: 'left', width: '100%', textAlign: 'center', borderRadius: 5}} onClick={() => { this.signupUser() }} type="submit" className="btn btn-primary mt-1 mt-md-2 mt-lg-1">
                                                    Register
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                }

                                {this.state.pageRegisterSubHeading == 'Verify OTP' &&
                                    <div className="card flex-grow-1 mb-0 height-300">
                                        <div className="card-body">
                                            <h3 style={{marginBottom: 0}} className="card-title">{this.state.pageRegisterSubHeading}</h3>
                                            {/* <span style={{paddingBottom: 10,  fontSize: 12, float: 'left', width: '100%'}}>Click phone number to edit/modify</span> */}
                                            <form onSubmit={this.handleSubmitForm}>
                                                {/* <p style={{marginBottom: 5}}>We have sent an OTP to: <span onClick={() => { this.setEnableChangeMobileNumber() }} style={{color: '#1a66ff', cursor: 'pointer'}}><b>{this.state.registerFormFields.mobile_number}</b></span></p> */}
                                                <p style={{marginBottom: 5}}>We have sent an OTP to: <b>{this.state.registerFormFields.mobile_number}</b></p>

                                                {this.state.isMobileNumberChangeRequested &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="login-email">Update mobile number</label>
                                                        <div className="mobileContainer">
                                                            <div className="countrySection">
                                                            <SelectSearch
                                                                options={this.state.countries}
                                                                name="country"
                                                                search
                                                                value={[this.state.countryCodeRegisterRemember]}
                                                                filterOptions={this.fuzzySearch}
                                                                onChange={(event) => { this.setCountryCodeRegister(event) }}
                                                                emptyMessage="No matched country found in search criteria"
                                                            />
                                                            </div>
                                                            <div className="mobileNumberSection">
                                                                <input
                                                                    type="number"
                                                                    value={this.state.registerMobileNumberUpdate}
                                                                    id="mobile_number"
                                                                    onChange={this.handleSetValueRegisterMobile}
                                                                    name="mobile_number"
                                                                    className="form-control"
                                                                    placeholder="Please enter mobile number"
                                                                />
                                                            </div>
                                                            {this.state.isEmptyRegisterExistingMobile ?
                                                            <span className="errorInput">Please enter mobile number</span>
                                                            : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <button onClick={() => { this.updateNewMobileNumber() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Update
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => { this.setEnableChangeMobileNumber() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Cancel
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                </>
                                                }

                                                {!this.state.isMobileNumberChangeRequested &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="otp">Enter verification code</label>
                                                        <OtpInput
                                                            id="otp"
                                                            value={this.state.registerOTP}
                                                            onChange={this.handleRegisterSetOTP}
                                                            numInputs={6}
                                                            inputStyle={{width: 50, height: 50, borderRadius: 10}}
                                                            separator={<span>&nbsp;-&nbsp;</span>}
                                                        />
                                                        {this.state.validationRegisterFormErrors.isEmptyOTP ?
                                                            <span className="errorInput">Please enter OTP</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <button onClick={() => { this.verifyOTPRegister() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Verify Now
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    {/* this.setRegisterEnable() */}
                                                    <button onClick={() => { this.setNewRegisterEnable() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Cancel
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    {this.state.showResendOTPButton &&
                                                        <button onClick={() => { this.resendOTPVerification() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                            Resend OTP
                                                        </button>
                                                    }
                                                    {this.state.startTimerRegister &&
                                                    <div className="timer-wrapper">
                                                        <CountdownCircleTimer
                                                        size={60}
                                                        strokeWidth={5}
                                                        duration={this.state.timerDuration}
                                                        isPlaying={this.state.startTimerRegister}
                                                        colors={[["#000", 0.33], ["#000", 0.33], ["#FF0000"]]}
                                                        //onComplete={() => [false, 1000]}
                                                        >
                                                        {renderTimeRegister}
                                                        </CountdownCircleTimer>
                                                    </div>
                                                    }
                                                </>
                                                }

                                            </form>
                                        </div>
                                    </div>
                                }

                                {this.state.pageRegisterSubHeading == 'Verify Email' &&
                                    <div className="card flex-grow-1 mb-0 height-300">
                                        <div className="card-body">
                                            <h3 style={{marginBottom: 0}} className="card-title">{this.state.pageRegisterSubHeading}</h3>
                                            {/* <span style={{paddingBottom: 10,  fontSize: 12, float: 'left', width: '100%'}}>Click email address to edit/modify</span> */}
                                            <form onSubmit={this.handleSubmitForm}>
                                                {/* <p style={{marginBottom: 5}}>We have sent an OTP to: <span onClick={() => { this.setEnableChangeEmail() }} style={{color: '#1a66ff', cursor: 'pointer'}}><b>{this.state.registerFormFields.email_address}</b></span></p> */}
                                                <p style={{marginBottom: 5}}>We have sent a verification code to: <b>{this.state.registerFormFields.email_address}</b></p>

                                                {this.state.isEmailChangeRequested &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="register-email">Email address</label>
                                                        <input
                                                            id="register-email"
                                                            type="email"
                                                            name="email_address"
                                                            onChange={this.handleSetValueRegisterEmail}
                                                            className="form-control"
                                                            placeholder="Please enter email address"
                                                            defaultValue={this.state.registerEmailUpdate}
                                                        />
                                                        {this.state.isEmptyRegisterExistingEmail ?
                                                        <span className="errorInput">Please enter email address</span>
                                                        : null
                                                        }
                                                        {this.state.isEmptyRegisterExistingisValidEmailAddress ?
                                                        <span className="errorInput">Please enter a valid email address</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <button onClick={() => { this.updateNewEmail() }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Update
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => { this.setEnableChangeEmail() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Cancel
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                </>
                                                }

                                                {!this.state.isEmailChangeRequested &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="otp">Enter verification code</label>
                                                        <OtpInput
                                                            id="otp"
                                                            value={this.state.registerOTP}
                                                            onChange={this.handleRegisterSetOTP}
                                                            numInputs={6}
                                                            inputStyle={{width: 50, height: 50, borderRadius: 10}}
                                                            separator={<span>&nbsp;-&nbsp;</span>}
                                                        />
                                                        {this.state.validationRegisterFormErrors.isEmptyOTP ?
                                                            <span className="errorInput">Please enter OTP</span>
                                                        : null
                                                        }
                                                    </div>
                                                    <button onClick={() => { this.verifyEmailRegister()  }}  type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Verify Now
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    <button onClick={() => { this.setNewRegisterEnable() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                        Cancel
                                                    </button> &nbsp;&nbsp;&nbsp;
                                                    {this.state.showResendOTPButton &&
                                                        <button onClick={() => { this.resendEmailVerification() }}  type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                            Resend Verification Code
                                                        </button>
                                                    }
                                                    {this.state.startTimerRegister &&
                                                    <div className="timer-wrapper">
                                                        <CountdownCircleTimer
                                                        size={60}
                                                        strokeWidth={5}
                                                        duration={this.state.timerDuration}
                                                        isPlaying={this.state.startTimerRegister}
                                                        colors={[["#000", 0.33], ["#000", 0.33], ["#FF0000"]]}
                                                        //onComplete={() => [false, 1000]}
                                                        >
                                                        {renderTimeRegister}
                                                        </CountdownCircleTimer>
                                                    </div>
                                                    }
                                                </>
                                                }

                                            </form>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

//export default AccountPageLogin;

const mapStateToProps = (state) => ({
    wishlist: [],
    cart: [],
});

const mapDispatchToProps = { cartRefresh, wishlistRefesh };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountPageLogin);
